import * as React from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, styled, Grid, Alert } from '@mui/material';
import { collection, getDoc, doc } from "firebase/firestore";

const ListItemTicket = styled("li")(() => ({
    padding: 2,
    // border: 1,
    // borderStyle: 'solid',
    // borderColor: 'green',
}));

const tickets = [];
for (var i = 0; i < 100; i++) {
    const number = `${i}`.padStart(6, '0');
    tickets.push({ id: `${i}`, data: () => { return { number } } });
}

export default function ConsultaFolio(props) {
    const { open, onClose, raffleSnap } = props;
    const [folio, setFolio] = React.useState('');
    const [status, setStatus] = React.useState('idle');
    const [folioData, setFolioData] = React.useState({});

    const handleColse = () => {
        setFolio('');
        setStatus('idle');
        onClose();
    }

    const handleCheckFolio = async () => {
        setStatus('loading');
        var folioRef = doc(collection(raffleSnap.ref, "selected"), folio);
        var folioSnap = await getDoc(folioRef);
        if (folioSnap.exists()) {
            setFolioData(folioSnap.data());
            setStatus('loaded');
        } else {
            setStatus('error');
            setTimeout(() => setStatus('idle'), 2000);
        }

    }

    return (<Dialog
        open={open}
        onClose={handleColse}
        scroll="body"
        maxWidth={"xs"}
        PaperProps={{ sx: { mt: "50px", verticalAlign: "top", minWidth: 350, mx: 0 } }}
    >
        <DialogTitle id="scroll-dialog-title">Consulta de folio</DialogTitle>
        <DialogContent>
            <Box
                sx={{
                    minWidth: 300
                }}
            >
                {status !== 'loaded' &&
                    <TextField
                        fullWidth
                        helperText="Intruduzca su folio"
                        id="folio"
                        label="Folio"
                        value={folio}
                        onChange={(event) => {
                            setFolio(event.target.value);
                        }}
                        sx={{
                            mt: 2
                        }}
                    />
                }
                {status === 'loading' && <Alert severity="info">Cargando...</Alert>}
                {status === 'error' && <Alert severity="error">No se encontro informacion.</Alert>}
                {status === 'loaded' &&
                    <Grid container
                        alignItems="center">
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">Nombre</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body2">{folioData.names} {folioData.lastNames}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">Estado</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body2">{folioData.paid ? 'Pagado' : 'Pendiente de pago'}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Numeros</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0,
                                    mt: 0
                                }}
                                component="ul"
                            >
                                {folioData.numbers?.map((item) => <ListItemTicket key={item}><Typography variant="body2">{item}</Typography></ListItemTicket>)}
                            </Box>
                        </Grid>
                    </Grid>
                }
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleColse}>Salir</Button>
            {status === 'idle' && <Button onClick={handleCheckFolio}>Consultar</Button>}
        </DialogActions>
    </Dialog>)
}