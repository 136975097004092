import { config } from "@app";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import Message from "./Message";

export default function Detalles(props) {
    const { onComprar, onConsultar, puedeComprar } = props;
    return (
        <main>
            {/* Hero unit */}
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                component="h2"
                                variant="h5"
                                align="center"
                                color="text.primary"
                            >
                                {config.raffle_tittle}<br/>
                                {config.raffle_tittle1}<br/>
                                {config.raffle_tittle2}
                            </Typography>
                            <Typography component="h3" variant="p" align="center" color="text.secondary" paragraph>
                                Desde ${config.packagePrices[1] || config.price} {config.currency}
                            </Typography>
                            <Typography variant="h5" align="center" color="text.secondary" paragraph>
                                COMPLETAMENTE EQUIPADO
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h9" align="center" color="text.secondary" paragraph>
                            🛑𝘾𝙤𝙣 𝙩𝙪 𝙗𝙤𝙡𝙚𝙩𝙤 𝙡𝙞𝙦𝙪𝙞𝙙𝙖𝙙𝙤 𝙥𝙖𝙧𝙩𝙞𝙘𝙞𝙥𝙖𝙨 𝙥𝙤𝙧👇<br />
                            <Typography variant="h6" align="center" color="text.primary" paragraph>
                             {config.raffle_tittle}
                             </Typography>
                            <br/>
                            <Typography variant="h7" align="center" color="text.primary" paragraph>
                            📅 Presorteo 📅<br/>
                            🏅PRIMER PRE- SORTEO MARTES 26 MARZO  $25,000💰<br/>
                            🏅SEGUNDO PRE- SORTEO MARTES 30 ABRIL  $25,000💰<br/>
                            <br/>
                            <br/>
                            </Typography>
                            PAGANDO DENTRO DE LAS PRIMERAS 24 HRS ⚡ + $30,0000 DE BONO💰
                            EN LA COMPRA DE 15 BOLETOS O MÁS TE LLEVARIAS $50,000 DE BONO DE PRONTO PAGO 💰
                            
                            <br/><br/>
                            🥇Pre-sorteo: 26 MARZO $25,000 pesos💰<br/>
                            🥈Pre-sorteo: 30 ABRIL $25,000 pesos💰<br/>
                            🚙Sorteo mayor: 10 DE MAYO Nissan Versa 2024 0KM + BONO DE PRONTO PAGO⚡


                            </Typography>
                        </Grid>
                    </Grid>
                    {!puedeComprar && <Message />}
                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                        <Button size="large" variant='contained' color='primary' onClick={onComprar} disabled={!puedeComprar}>Comprar</Button>
                        <Button size="large" variant='outlined' onClick={onConsultar}>Consultar boleto</Button>
                    </Stack>
                </Container>
            </Box>
        </main>
    )
}