import { doc } from 'firebase/firestore'
import { firestore } from './firebase';

const raffleId = '5BmPizhOwmX3lp2szDho';
const raffleRef = doc(firestore, "raffles", raffleId);
const numbersPhone = (['+526471104429', '+526474870254']);
export const config = {
    filterLimit: undefined,
    defaultLimit: 100, //Limite por default en la seleccion de boletos
    searchLimit: 20, //Limite por default en la seleccion de boletos
    price: 80, // Precio por boleto
    packagePrices: {
        //1: 42 // 1 boleto cuesta 42
    },
    number: 7, // Numero de sorteo
    raffle_tittle: 'NISSAN VERSA ADVANCE 2024🏆',
    raffle_tittle1: '🥇Nissan Versa 2024 0KM 🥇',
    raffle_tittle2: '10 DE MAYO',
    currency: 'MXN',
    raffleId,
    rafflePad: 5,
    raffleOpportunities: 1, //Default para todas, configurar en firebase el deseado en el campo: opportunities
    raffleManualOpportunities: 1, //Default para todas, configurar en firebase el deseado en el campo: manualOpportunities
    isDev: process.env.NODE_ENV === 'development',
    raffleRef,
    ticketPacks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 20, 30, 50],
    sendToNumber: () => numbersPhone[Math.floor(Math.random() * numbersPhone.length)]
}
