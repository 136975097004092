import * as React from 'react';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemIcon, ListItemText, Alert } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const paymentOptions = {
    transfer: [
        {
            bank: 'BBVA',
            clabe: '',
            account: '',
            card_number: ['4152314190277775'],
            destinatary: 'Daniel Duarte Reyes'
        }
    ],
    convenience_store: [
        {
            bank: 'BBVA',
            accounts: [
                '4152313811167290'

            ]
        },
        {
            bank: 'HSBC',
            accounts: [
                '4213168059621185'
            ]
        },
        {
            bank: 'BANCOPPEL',
            accounts: [
                '4169161427673883'
            ]
        },
    ]
}

const Bank = (props) =>
    <ListItemIcon
        sx={{
            mr: 1,
            minWidth: 120
        }}
    >
        <Typography variant="button" display="block" gutterBottom color={'primary'}>
            {props.bank}
        </Typography>
    </ListItemIcon>

export default function FormasPago(props) {
    const { open, onClose } = props;
    React.useEffect(() => {
        if (!open) {
            window.onpopstate = () => { }
            return;
        }
        window.onpopstate = function (e) {
            // console.log("history-back-listener formasPago");
            if (document.location.hash === "") {
                onClose();
            }
        }
    }, [onClose])

    return (<Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={"xs"}

    >
        <DialogTitle id="scroll-dialog-title">Formas de pago</DialogTitle>
        <DialogContent
            sx={{
                p: 0,
                m: 0
            }}>
            <Alert severity="info">Cuando haga su pago favor de confirmar al mismo numero al que reservo su compa. <br />A continuacion se muestran nuestras formas de pago.</Alert>
            {paymentOptions.transfer && paymentOptions.transfer.length > 0 &&
                <div>
                    <Alert severity="error">Pago solo por transferencia</Alert>
                    <List>
                        {paymentOptions.transfer.map((transferItem, i) =>
                            <ListItem key={i}
                                sx={{ pt: 0, pb: 0 }}
                                dense
                                divider
                            >
                                <Bank bank={transferItem.bank} />
                                <List sx={{ pt: 0, pb: 0 }}>
                                    {transferItem.clabe !== '' && <ListItemText primary={transferItem.clabe} />}
                                    {transferItem.clabe !== '' && <ListItemText secondary={"CLABE Interbancaria"} sx={{ mt: -1 }} />}
                                    {transferItem.account !== '' && <ListItemText primary={transferItem.account} />}
                                    {transferItem.account !== '' && <ListItemText secondary={"Cuenta"} sx={{ mt: -1 }} />}
                                    {transferItem.card_number !== '' && <ListItemText primary={transferItem.card_number} />}
                                    {transferItem.card_number !== '' && <ListItemText secondary={"Numero Tarjeta"} sx={{ mt: -1 }} />}
                                    <ListItemText primary={transferItem.destinatary} />
                                </List>
                            </ListItem>
                        )}
                    </List>
                </div>
            }
            {paymentOptions.convenience_store && paymentOptions.convenience_store.length > 0 &&
                <div>
                    <Alert severity="error">Pago solo en tienda de conveniencia o ventanilla</Alert>
                    <List>
                        {paymentOptions.convenience_store.map((convenience_store, i) =>
                            <ListItem key={i}
                                sx={{ pt: 0, pb: 0 }}
                                dense
                                divider
                            >
                                <Bank bank={convenience_store.bank} />
                                <List dense sx={{ pt: 0, pb: 0 }}>
                                    {convenience_store.accounts.map((item, j) =>
                                        <ListItemText key={j}
                                            primary={item}
                                        />)}
                                </List>
                            </ListItem>
                        )}
                    </List>
                </div>
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Entendido</Button>
        </DialogActions>
    </Dialog>)
}